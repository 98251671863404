import styled from "@emotion/styled";
import colors from "../lib/colors";

const cellPadding = {padding: "0.5rem 0.75rem"};

const Document = styled("div")({
  lineHeight: 1.75,
  "@media(max-width: 500px)": {
    lineHeight: 1.5,
  },
  wordBreak: "break-word",
  " p, pre, .gatsby-highlight": {"&:not(:last-child)": {marginBottom: "1.5rem"}},
  " h1, h2, h3, h4, h5, h6": {
    marginBottom: "1.5rem",
    lineHeight: 1.2,
    "&:not(:first-child)": {
      marginTop: "3em",
    },
  },
  " h1": {marginBottom: "2rem"},
  " hr": {
    marginTop: "3rem",
    marginBottom: "3rem",
    border: "none",
    borderTop: `1px solid ${colors.borderGray}`,
  },
  " ul, ol": {
    paddingLeft: "2rem",
    "&:not(:last-child)": {marginBottom: "2rem"},
  },
  " li:not(:last-child)": {marginBottom: "0.5rem"},
  " a": {color: colors.link, fontWeight: "bold"},
  " a:hover": {color: colors.linkHover},
  " img:not(.gatsby-resp-image-image), .chart-container": {
    display: "block",
    margin: "6rem auto",
    maxWidth: "100%",
  },
  ".transcript-avatar img": {
    margin: 0,
  },
  ".gatsby-resp-image-wrapper": {
    marginBottom: "3rem",
  },
  " p:not(:first-child) > .gatsby-resp-image-wrapper": {
    marginTop: "5rem",
    marginBottom: "6rem",
  },
  " .twitter-tweet": {
    marginLeft: "auto",
    marginRight: "auto",
    ":not(:first-child)": {marginTop: "5rem !important"},
    ":not(:last-child)": {marginBottom: "6rem !important"},
  },
  " strong": {fontWeight: "bold"},
  ' pre:not([class*="language-"])': {
    fontSize: "0.7rem",
    overflowX: "auto",
    marginLeft: "-1rem",
    marginRight: "-1rem",
    padding: "0.5rem 1rem",
    backgroundColor: colors.fade(colors.dark, 0.05),
  },
  ' code[class*="language-"]': {
    background: "#3c1177",
    color: "#fff",
  },
  ' *:not(pre) > code[class*="language-"]': {
    padding: ".1em",
    borderRadius: ".3em",
    whiteSpace: "normal",
  },

  ' pre > code[class*="language-"]': {
    background: "transparent",
  },
  ' pre[class*="language-"]': {
    fontSize: "0.7rem",
    margin: 0,
    background: "#3c1177",
  },
  ' pre[class*="language-js"]': {
    background: "#1f083e",
  },
  " code.inline": {backgroundColor: colors.fade(colors.dark, 0.05), padding: "0 0.2rem"},
  " blockquote": {borderLeft: `0.3rem solid ${colors.brand}`, marginLeft: 0, paddingLeft: "2rem"},
  " .gatsby-resp-iframe-wrapper": {
    ":not(:first-child)": {marginTop: "5rem"},
    ":not(:last-child)": {marginBottom: "6rem"},
  },
  " table": {
    borderCollapse: "collapse",
    width: "100%",
  },
  " th": {
    fontWeight: "bold",
    fontSize: "0.7rem",
    // color: docVars.colors.secondaryText,
    textAlign: "left",
    ...cellPadding,
  },

  " tbody tr:nth-of-type(2n + 1)": {
    backgroundColor: "#fafafa",
  },
  " td": {
    // color: docVars.colors.text,
    ...cellPadding,
  },
});
export default Document;
